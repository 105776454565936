import React, { useState, useEffect } from 'react';
import { FaGlobe, FaTimes } from 'react-icons/fa';

const FloatingGoogleTranslate = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [languages, setLanguages] = useState([]); // Dynamic language list

  useEffect(() => {
    // Add Google Translate script
    const addScript = () => {
      if (!document.querySelector('script[src*="translate.google.com"]')) {
        const script = document.createElement('script');
        script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        document.body.appendChild(script);
      }
    };

    // Initialize Google Translate
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          autoDisplay: false,
        },
        'google_translate_element_hidden'
      );

      // Extract languages after initialization
      setTimeout(() => {
        const combo = document.querySelector('.goog-te-combo');
        if (combo) {
          const options = Array.from(combo.options).map((option) => ({
            name: option.text,
            code: option.value,
          }));
          // Filter out empty or invalid options and sort alphabetically
          const validLanguages = options
            .filter((lang) => lang.code && lang.name && lang.code !== '')
            .sort((a, b) => a.name.localeCompare(b.name));
          setLanguages(validLanguages);
        }
      }, 1000); // Delay to ensure the combo box is populated
    };

    addScript();

    // Cleanup
    return () => {
      delete window.googleTranslateElementInit;
    };
  }, []);

  // Function to translate the page to the selected language
  const translatePage = (langCode) => {
    const googleTranslateCombo = document.querySelector('.goog-te-combo');
    if (googleTranslateCombo) {
      googleTranslateCombo.value = langCode;
      googleTranslateCombo.dispatchEvent(new Event('change')); // Trigger translation
    }
    setIsOpen(false); // Close the menu after selection
  };

  return (
    <div className="fixed bottom-24 right-4 z-50 flex flex-col items-end">
      {/* Floating Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-12 h-12 rounded-full bg-blue-600 text-white shadow-lg flex items-center justify-center hover:bg-blue-700 transition-all duration-300 border border-blue-500"
        aria-label={isOpen ? 'Close translator' : 'Open translator'}
      >
        {isOpen ? <FaTimes className="w-5 h-5" /> : <FaGlobe className="w-6 h-6" />}
      </button>

      {/* Language Menu */}
      {isOpen && (
        <div className="mt-4 bg-white rounded-lg shadow-xl border border-gray-200 p-4 w-48 max-h-64 overflow-y-auto">
          <h3 className="text-lg font-semibold text-gray-800 mb-3">Select Language:</h3>
          {languages.length === 0 ? (
            <p className="text-sm text-gray-500">Loading languages...</p>
          ) : (
            <ul className="space-y-2">
              {languages.map((lang) => (
                <li key={lang.code}>
                  <button
                    onClick={() => translatePage(lang.code)}
                    className="w-full text-left text-sm text-gray-700 hover:bg-blue-100 hover:text-blue-600 px-2 py-1 rounded transition-colors"
                  >
                    {lang.name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {/* Hidden Google Translate Element */}
      <div id="google_translate_element_hidden" className="hidden"></div>
    </div>
  );
};

export default FloatingGoogleTranslate;