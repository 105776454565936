import React, { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A28DFF", "#FF5678"];

const BlogCountList = () => {
  const [blogCounts, setBlogCounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogCounts = async () => {
      try {
        const response = await fetch(
          "https://nodejs-serverless-function-express-henna-theta.vercel.app/api/blogsByAuthor"
        );
        const data = await response.json();
        if (data.success) {
          setBlogCounts(data.data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogCounts();
  }, []);

  if (loading)
    return <p className="text-center text-gray-500">Loading...</p>;
  if (error)
    return <p className="text-center text-red-600">Error: {error}</p>;

  return (
    <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">
        Blog Posts Overview
      </h1>
      <div className="flex flex-col md:flex-row gap-8">
        {/* Author List Section */}
        <div className="flex-1 p-4 bg-gray-50 rounded-lg shadow-sm">
          <h2 className="text-2xl font-semibold text-green-700 mb-4">
            Posts by Author
          </h2>
          <ul className="space-y-3">
            {blogCounts.map((author) => (
              <li
                key={author.authorId}
                className="flex justify-between items-center border-b pb-2"
              >
                <span className="text-gray-700 font-medium">
                  {author.authorName}
                </span>
                <span className="text-gray-900 font-bold bg-green-100 px-2 py-1 rounded-full">
                  {author.blogCount} posts
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* Pie Chart Section */}
        <div className="flex-1 p-4 bg-gray-50 rounded-lg shadow-sm">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
            Blog Count Distribution
          </h2>
          <div className="flex justify-center">
            <PieChart width={550} height={350}>
              <Pie
                data={blogCounts}
                dataKey="blogCount"
                nameKey="authorName"
                cx="50%"
                cy="50%"
                outerRadius={120}
                fill="#8884d8"
                label={({ name, value }) => `${name}: ${value}`}
              >
                {blogCounts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{
                  backgroundColor: "#fff",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              />
            </PieChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCountList;