
// Payment.jsx
import React, { useState } from 'react';
import Confetti from 'react-dom-confetti';
import GooglePayButton from "@google-pay/button-react";
import './Payment.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Payment = ({ amount, onPaymentSuccess, onPaymentCancel, bookingDetails }) => {
  // State management for component
  const [paymentType, setPaymentType] = useState('full'); // Default to full payment
  const [showConfetti, setShowConfetti] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
const navigate = useNavigate()
  // Google Pay payment request configuration
  const paymentRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["MASTERCARD", "VISA"]
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "example",
            gatewayMerchantId: "ABC123"
          }
        }
      }
    ],
    merchantInfo: {
      merchantId: "BCR2DN4TX6WZLPTS",
      merchantName: "Mantra Mountain"
    },
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPriceLabel: "Total",
      totalPrice: paymentType === 'partial' ? (amount * 0.3).toString() : amount.toString(),
      currencyCode: "USD",
      countryCode: "US"
    }
  };

  const handlePaymentTypeChange = (type) => {
    setPaymentType(type);
  };

  const handleGooglePay = async (paymentData) => {
    try {
      setIsLoading(true);
      setError(null);
  
      // Log the full payment data for debugging
      console.log('Full Payment Data from Google Pay:', paymentData);
  
      const paymentPayload = {
        paymentData: {
          ...paymentData,
          status: 'success', // Simulated for testing
          amount: paymentType === 'partial' ? (amount * 0.3) : amount,
          transactionId: paymentData.transactionInfo?.transactionId || Date.now().toString()
        },
        bookingDetails: {
          packageName: bookingDetails.packageData.packageName,
          bookingDate: bookingDetails.date,
          customerName: bookingDetails.formData.name,
          customerEmail: bookingDetails.formData.email,
          contactNumber: bookingDetails.formData.contactNumber,
          numberOfPeople: bookingDetails.numberOfPeople
        }
      };
  
      console.log('Sending payment payload to server:', paymentPayload); // Log payload
      // const response = await fetch('http://localhost:5000/api/verify-payment', {
      const response = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/verify-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentPayload),
      });
  
      const result = await response.json();
      console.log('Payment verification response:', result); // Log server response
  
      if (response.ok) {
        toast.success('Payment successfully completed! Please chack your email for invoice', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setShowConfetti(true);
        setTimeout(() => {
          onPaymentSuccess(result); // Pass the result to the success handler
          setIsModalOpen(false);
          window.location.reload()
        }, 3000);
        navigate('/')
      } else {
        throw new Error(result.error || 'Payment verification failed');
      }
    } catch (err) {
      console.error('Payment error:', err);
      setError(err.message || 'Payment processing failed');
    } finally {
      setIsLoading(false);
    }
  };

  // Confetti animation configuration
  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: '10px',
    height: '10px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };

  // Don't render anything if modal is closed
  if (!isModalOpen) {
    return null;
  }

  return (
    <div className="payment-modal">
      <div className="payment-content">
        <h2>Pay ${paymentType === 'partial' ? (amount * 0.3).toFixed(2) : amount.toFixed(2)}</h2>
        <p><strong className='text-red-500'>NOTE: You won’t be charged right now</strong></p>

        <p className="payment-note">
          <strong>Heads up!</strong> We're currently integrating this feature, so you won't be charged the actual amount. 
          Just follow the process to complete your booking. Thank you for your patience!
        </p>
        <div>
          <button onClick={() => handlePaymentTypeChange('partial')}>Partial Payment (30%)</button>
          <button onClick={() => handlePaymentTypeChange('full')}>Full Payment</button>
        </div>
        {isLoading && <div className="loading">Processing payment...</div>}
        {!isLoading && (
          <GooglePayButton
            environment="TEST"
            paymentRequest={paymentRequest}
            onLoadPaymentData={handleGooglePay}
            onError={error => setError(error.toString())}
            className="google-pay-button"
            buttonType="buy"
            buttonColor="black"
            buttonSizeMode="fill"
          />
        )}
        {error && <p className="error">{error}</p>}
        <button 
          onClick={onPaymentCancel}
          className="cancel-button"
        >
          Cancel
        </button>
      </div>
      <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Confetti active={showConfetti} config={confettiConfig} />
      </div>
    </div>
  );
};

export default Payment;