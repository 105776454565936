import React, { useEffect, useState } from 'react';
import { Users, Package, FileText, BookOpen, BarChart3 } from 'lucide-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer
} from 'recharts';
import StatCard from './StatCard';
import axios from 'axios';
import BlogCountList from '../BlogCountPieChart/BlogCountPieChart';
import BookingsChart from './BookingChart';

const StatsSection = () => {
  const [users, setUsers] = useState([]);
  const [totalPackages, setTotalPackages] = useState(0);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [totalBookings, setTotalBookings] = useState(0);
  const [packageData, setPackageData] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        if (!token) return;

        const [usersResponse, packagesResponse, blogsResponse, bookingsResponse] = await Promise.all([
          axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/users/all-users', {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/all', {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/all', {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/booking/count-bookings', {
            headers: { Authorization: `Bearer ${token}` }
          })
        ]);

        // Ensure package data is processed correctly
        const processedPackageData = packagesResponse.data.map(pkg => ({
          name: pkg.title,
          value: pkg.bookingsCount || 1 // Ensure it never breaks the chart
        }));

        const processedBlogData = blogsResponse.data
          .map(blog => ({
            name: blog.title,
            views: blog.views || 92750
          }))
          .sort((a, b) => b.views - a.views)
          .slice(0, 5);

        const processedBookingData = bookingsResponse.data;

        setUsers(usersResponse.data);
        setTotalPackages(packagesResponse.data.length);
        setTotalBlogs(blogsResponse.data.length);
        setTotalBookings(bookingsResponse.data);
        setPackageData(processedPackageData);
        setBlogData(processedBlogData);
        setBookingData(processedBookingData);
      } catch (err) {
        setError('Failed to load dashboard data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) return <div>Loading stats...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="space-y-8 bg-gray-100 min-h-screen">
      {/* Stat Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <StatCard title="Total Users" value={users.length} icon={Users} />
        <StatCard title="Total Packages" value={totalPackages} icon={Package} />
        <StatCard title="Total Blogs" value={totalBlogs} icon={FileText} />
        <StatCard title="Total Bookings" value={totalBookings} icon={BookOpen} />
      </div>
      <BlogCountList />

      {/* Charts Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        

        <BookingsChart/>

        {/* Blog Views Bar Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4">Top 5 Blogs by Views</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={blogData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="views" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Monthly Bookings Bar Chart */}
        {/* <div className="bg-white p-6 rounded-lg shadow-md col-span-full">
          <h3 className="text-lg font-semibold mb-4">Monthly Bookings</h3>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={bookingData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div> */}
      </div>

      {/* Additional Analytics Section */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-4 flex items-center">
          <BarChart3 className="mr-2" /> Analytics Overview
        </h3>
        <p className="text-gray-600">This dashboard provides insights into user engagement, bookings, and content performance. Use this data to improve strategies and optimize experiences for your users.</p>
      </div>
    </div>
  );
};

export default StatsSection;
