// src/Components/OfferBanner/OfferBanner.jsx
import React, { useState, useEffect } from "react";

const OfferBanner = () => {
  const [timeLeft, setTimeLeft] = useState(24 * 60 * 60); // 24-hour countdown
  const [isExpanded, setIsExpanded] = useState(false); // Toggle for expansion

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  return (
    <div
      className={`fixed left-0 top-1/3 transform -translate-y-1/2 z-50 transition-all duration-300 ${
        isExpanded ? "w-64" : "w-16"
      }`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className="mt-96 bg-gradient-to-r from-red-600 to-red-400 text-white p-3 rounded-r-lg shadow-lg h-32 flex items-center justify-start">
        {isExpanded ? (
          <div className="flex flex-col items-start gap-1">
            <span className="bg-yellow-300 text-red-600 px-2 py-1 rounded-full text-xs font-bold animate-pulse">
              LIMITED
            </span>
            <p className="text-sm font-semibold">Save 20% Now!</p>
            <p className="text-xs">
              Code: <span className="font-bold text-yellow-200">TREK10</span>
            </p>
            <div className="flex gap-1 text-xs bg-white/20 px-2 py-1 rounded">
              <span>{hours.toString().padStart(2, "0")}</span>:
              <span>{minutes.toString().padStart(2, "0")}</span>:
              <span>{seconds.toString().padStart(2, "0")}</span>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <span className="text-sm font-bold transform -rotate-90 whitespace-nowrap text-yellow-200">
              SAVE 20%
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferBanner;